@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
body {
  margin: 0;
  font-family:'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#recaptcha-container {
  margin: 20px;
}
.bg {
  /* The image used */
  
  background-image: url("./images/bg.png");
 
  height: 80vh; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media (max-width: 640px) { 
  .bg {
    /* The image used */
    background-position: right;
  }
}

.dollar-input {
  position: relative;
}

.dollar-input::before {
  content: '$';
  position: absolute;
  left: 0.75rem;
  top: 1.5rem;
  color: black;
}